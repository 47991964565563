
    import { defineComponent, PropType } from "vue";
    import Loading from "../UI/Loading/index";

    import usePageScroll, { PropsType } from "@/hooks/usePageScroll";

    export default defineComponent({
        props: {
            filterFunc: Function,
            url: String,
            params: Object,
            show: {
                type: Boolean,
                default: false,
            },
            showLoading: {
                type: Boolean,
                default: false,
            },
        },
        components: {
            Loading,
        },
        setup(props) {
            const { loading, result , empty, totalResult } = usePageScroll(props as PropsType);
            const loadOption = {
                text: "加载中...",
                color: "#df0024",
                textColor: "#df0024",
            };
            return {
                result,
                loading,
                loadOption,
                empty,
                totalResult
            };
        },
    });
